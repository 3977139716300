import * as React from 'react'
import { Box, Input, Label, Textarea, Checkbox, Image, Button, Link } from 'theme-ui';

interface CompanyType {
  email: string,
  firstName: string,
  lastName: string,
  title: string,
  content: string
}



const ContactCompany = () => {

  const [textField, setTextField] = React.useState<CompanyType>({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    content: "",
  });

  //input handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextField(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // form handler
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!textField.firstName || !textField.lastName || !textField.email || !textField.title || !textField.content) {
      alert("すべての項目を入力してください。");
      return;
    }

    try {
      const response = await fetch("https://1u4nvii4ke.microcms.io/api/v1/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-Microcms-Api-Key': '',
        },
        body: JSON.stringify(textField),
      });

      if (response.ok) {
        alert("相談申し込みが完了しました。");
        setTextField({ firstName: "", lastName: "", email: "", title: "", content: "" }); // input init
      } else {
        alert("エラーが発生しました。");
        console.log("server response error:", await response.json());
      }
    } catch (error) {
      alert("少々後でもう一度お試しください。");
      console.log("network error:", error);
    }
  };

  return (
    <Box sx={{ paddingY: "5%", bg: '#999999', paddingX: "2%", overflow: 'auto' }}>
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', display: [`block`, `block`, `block`, `flex`], color: '#fff', paddingX: ['1rem', '1rem', '2rem', 0], paddingY: ['1.5rem', '2rem', '2rem'] }}>
        <Box sx={{ width: ['100%', '100%', '100%', '50%'], marginBottom: ['3rem', '3rem', '3rem', 0] }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', }}>
            <Box sx={{ paddingRight: ['0', '0', '2rem'] }}>
              <Box sx={{ marginBottom: '2rem' }}>
                <Box as="h3" sx={{ fontSize: ['1.5rem', '2rem', '2rem'], color: '#333', marginBottom: '1rem' }}>連絡する</Box>
                <Box as="p">以下のフォームを使用してメッセージを送信するか、下のSNSをクリックして友達を追加して連絡してください。できるだけ早くご連絡いたします。</Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
                <Box as="ul" sx={{ listStyle: 'none', padding: '0' }}>
                  <Box as="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Box as="figure" sx={{ marginRight: '1rem' }}>
                      <Image sx={{ width: '32px', maxWidth: '32px', }} src="/pin_icon.png"></Image>
                    </Box>
                    <Box>
                      <Box as="p" sx={{ fontSize: ['0.75rem', '1rem', '1.2rem'], color: '#555' }}>Address</Box>
                      <Box as="p" sx={{ fontSize: ['0.9rem', '1rem', '1rem'] }}>Sinjuku 7chome 7-26, Tokyo</Box>
                    </Box>
                  </Box>
                  <Box as="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Box as="figure" sx={{ marginRight: '1rem' }}>
                      <Image sx={{ width: '28px', maxWidth: '28px' }} src="/phone_icon.png"></Image>
                    </Box>
                    <Box>
                      <Box as="p" sx={{ fontSize: ['0.75rem', '1rem', '1.2rem'], color: '#555' }}>Phone Number</Box>
                      <Box as="p" sx={{ fontSize: ['0.9rem', '1rem', '1rem'] }}>+123-456-7890</Box>
                    </Box>
                  </Box>
                  <Box as="li" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Box as="figure" sx={{ marginRight: '1rem' }}>
                      <Image sx={{ width: '25px', maxWidth: '25px' }} src="/email_icon.png"></Image>
                    </Box>
                    <Box>
                      <Box as="p" sx={{ fontSize: ['0.75rem', '1rem', '1.2rem'], color: '#555' }}>E-Mail</Box>
                      <Box as="p" sx={{ fontSize: ['0.9rem', '1rem', '1rem'] }}>info@ukmeta.jp</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box as="ul" sx={{ listStyle: 'none', display: 'flex', color: '#fff' }}>
                <Box as="li" sx={{ marginRight: '20px' }}>
                  <Link href="" sx={{ display: 'block', color: '#333', width: ['35px', '35px', '50px'] }}>
                    <Image src="/line.png" />
                  </Link>
                </Box>
                <Box as="li" sx={{ marginRight: '20px', }}>
                  <Link href="" sx={{ display: 'block', color: '#333', width: ['35px', '35px', '50px'] }}>
                    <Image src="/kakao.png" />
                  </Link>
                </Box>
                <Box as="li">
                  <Link href="" sx={{ display: 'block', color: '#333', width: ['35px', '35px', '50px'] }}>
                    <Image src="/insta.png" />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box as="form" onSubmit={onSubmit} sx={{ display: 'flex', maxWidth: "1200px", margin: '0 auto', width: ['100%', '100%', '100%', '50%'] }} >
          <Box sx={{ width: '100%', color: '#333', paddingY: ['2rem'], paddingX: ['1rem'], borderRadius: "30px", bg: '#fff' }}>
            <Box as="h3" sx={{ textAlign: 'center', fontSize: ['1rem', '1.5rem', '2rem'], marginBottom: '1rem' }}>Send your Message</Box>
            <Input id="first-name" placeholder='firstName' name='firstName' value={textField.firstName} onChange={handleChange} sx={{
              "::placeholder": {
                color: "#999",
                fontSize: ['0.75rem']
              }, marginBottom: '1rem', paddingX: ["0.75rem", "1rem"], paddingTop: ["0.75rem"], outline: 'none', border: 'unset', borderBottom: '1px solid #999'
            }}></Input>
            <Input id="last-name" placeholder='lastName' name='lastName' value={textField.lastName} onChange={handleChange} sx={{
              "::placeholder": {
                color: "#999",
                fontSize: ['0.75rem']
              }, marginBottom: '1rem', paddingX: ["0.75rem", "1rem"], paddingTop: ["0.75rem"], outline: 'none', border: 'unset', borderBottom: '1px solid #999'
            }}></Input>
            <Input id="email-name" placeholder='Email' name='email' type='email' value={textField.email} onChange={handleChange} sx={{
              color: '#999',
              "::placeholder": {
                color: "#999",
                fontSize: ['0.75rem']
              }, marginBottom: '1rem', paddingX: ["0.75rem", "1rem"], paddingTop: ["0.75rem"], outline: 'none', border: 'unset', borderBottom: '1px solid #999'
            }}></Input>
            <Input id="title" placeholder='Title' name='title' value={textField.title} onChange={handleChange} sx={{
              "::placeholder": {
                color: "#999",
                fontSize: ['0.75rem']
              }, marginBottom: '1rem', paddingX: ["0.75rem", "1rem"], paddingTop: ["0.75rem"], outline: 'none', border: 'unset', borderBottom: '1px solid #999'
            }}></Input>
            <Textarea placeholder='Message' name='content' value={textField.content} onChange={handleChange} sx={{
              "::placeholder": {
                color: "#999",
                fontSize: ['0.75rem']
              }, height: '150px', marginBottom: '1rem', paddingX: ["0.75rem", "1rem"], paddingTop: ["0.75rem"], outline: 'none', border: 'unset', borderBottom: '1px solid #999'
            }}></Textarea>
            <Button type="submit" value="" sx={{ marginTop: '1rem', borderRadius: '50px', cursor: 'pointer' }} >送信する</Button>
          </Box>
        </Box>
      </Box>
    </Box >
  )
};

export default ContactCompany
