import * as React from 'react'
import { Box, Image } from 'theme-ui';
import Header from '../header';

const ContactVisual = () => (
  <div>
    <Header />
    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%", objectFit: "cover", height: '50vh', padding: ['2rem', '2rem', '2rem', 0] }}>
      <Box as="h3" sx={{ fontSize: ["1.5rem", "2rem", "2.5rem"], marginBottom: '2rem' }}>Contact Us</Box>
      <Box as="p" sx={{ fontSize: "1rem" }}>より素晴らしいプロジェクトのためにあなたの連絡を待っています。</Box>
    </Box>
  </div>
)


export default ContactVisual