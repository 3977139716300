import * as React from 'react'
import Layout from '../@lekoarts/gatsby-theme-cara/components/layout'
import HeroAboutUs from '../@lekoarts/gatsby-theme-cara/components/AboutUs/heroAboutUs'
import Contact from '../@lekoarts/gatsby-theme-cara/components/AboutUs/contact'
import { Box } from 'theme-ui'
import Footer from '../@lekoarts/gatsby-theme-cara/components/footer'
import { History } from '../@lekoarts/gatsby-theme-cara/components/AboutUs/history'
import ContactVisual from '../@lekoarts/gatsby-theme-cara/components/ContactUs/contactVisual'
import ContactCompany from '../@lekoarts/gatsby-theme-cara/components/ContactUs/contactCompany'
import ContactFooterOut from '../@lekoarts/gatsby-theme-cara/components/ContactUs/contactFooterOut'

const ContactUs = () => (
  <Layout>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden scroll',
        transform: 'translate3d(0px, 0px, 0px)',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          width: '100%',
          // height: ['280vh', '250vh', '280vh', '280vh', '200vh'],
          transform: 'translate3d(0px, 0px, 0px)',
        }}
      >
        <ContactVisual />
        <ContactCompany height="50px" />
        <ContactFooterOut />
        <Footer />
      </Box>
    </Box>
  </Layout>
)


export default ContactUs
