import { Box } from "theme-ui"
import * as React from 'react'

const ContactFooterOut = () => {
  return (
    <Box sx={{ height: '20vh' }}>

    </Box>
  )
}


export default ContactFooterOut;